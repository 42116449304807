@import './../../css/var';

.content_wrapper {
    display: block;
    flex-shrink: 0;
    height: 38px;
    overflow-x: hidden;
    position: relative;
    width: 38px;
    z-index: 0;
}

.add_track_to_play_list_button {
    // margin-right: 10px;
    outline: initial;
    pointer-events: none;
}

/*
.add_track_to_play_list_button {
    background-color: #fff;
    border: 0;
    box-sizing: border-box;
    color: #5a5a5a;
    font-family: Tahoma, sans-serif;
    font-size: 25px;
    font-weight: normal;
    height: 100%;
    line-height: 1;
    padding: 5px 0 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: row;
    &:active {

    }
}
*/

.select_play_list {
    appearance: none;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    display: block;
    font-family: $main-font-family-monospace;
    height: 100%;
    opacity: 0;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;

    &:focus {
        min-width: 100%;
        width: auto;

        option:first-child {
            display: none;
        }
    }
}
