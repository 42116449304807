.audio_tag {
    display: none;
}

.audio {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: normal;

    // margin-bottom: 10px;
    padding: 6px;
    width: 100%;
}

.time {
    padding: 0 4px;
}

.sound_range {
    flex-shrink: 0;
    width: 76px;
}

.download_button {
    display: block;
    outline: 0;
}
