@import './../../css/var';

$slider-thumb-color: rgb(64 138 240 / 100%);
$slider-thumb-color-active-outline: rgb(64 138 240 / 20%);
$slider-background-line-color: #dadada;
$slider-thumb-radius: 6px;

.range_bar {
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    height: 38px;
    padding: 0;
    position: relative;
    width: 100%;

    &.wrapper__active {
        display: block;
    }

    &.wrapper__disable {
        pointer-events: none;
    }
}

.progress_bar__wrapper {
    background-color: $slider-background-line-color;
    border-radius: 1px;
    height: 2px;
    left: $slider-thumb-radius;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: calc(100% - #{$slider-thumb-radius * 2});
    z-index: 0;
}

.progress_bar {
    background-color: $slider-thumb-color;
    border-radius: 1px;
    height: 100%;
    transform-origin: left center;
    width: 100%;
}

.point_bar__wrapper {
    height: 100%;
    left: $slider-thumb-radius;
    position: absolute;
    top: 0;
    width: calc(100% - #{$slider-thumb-radius * 4});
    z-index: 1;
}

.point_bar {
    background-color: $slider-thumb-color;
    border-radius: $slider-thumb-radius;
    display: block;
    height: $slider-thumb-radius * 2;
    margin-top: -$slider-thumb-radius;
    position: absolute;
    top: 50%;
    width: $slider-thumb-radius * 2;
}

.wrapper__disable {
    .point_bar {
        background-color: $slider-background-line-color;
    }
}

.wrapper__active {
    .point_bar {
        box-shadow: 0 0 0 $slider-thumb-radius $slider-thumb-color-active-outline;
    }
}

.input_range {
    border: 0;
    cursor: pointer;
    display: block;
    height: 100%;
    left: $slider-thumb-radius;
    margin: 0;
    opacity: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 0;
    width: calc(100% - #{$slider-thumb-radius * 2});
    z-index: 2;
}

/* stylelint-disable-next-line media-query-no-invalid */
@media #{$media-narrow-screen-width} {
    .range_bar__hide_for_narrow {
        display: none;
    }
}
